import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ToolStatus from './ToolStatus'
import LogoGenerator from '../../LogoGenerator'
import {
  Container,
  IconSection,
  Content,
  Title,
  Details,
  Version,
} from './styles'

function titleCase(str) {
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i += 1) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

function ToolCard({ data = {} }) {
  const [status, setStatus] = useState('offline')
  const [version, setVersion] = useState()
  const { name = '' } = data

  const homepage = `${window.location.protocol}//${window.location.host}/${name}`

  return (
    <Container target="_blank" href={homepage} status={status}>
      <IconSection>
        <LogoGenerator tool={name} />
      </IconSection>
      <Content>
        <Title>
          <h3>{titleCase(name.replace('-', ' '))}</h3>
        </Title>
        <Details>
          <ToolStatus
            url={name}
            status={status}
            setStatus={setStatus}
            setVersion={setVersion}
          />
        </Details>
      </Content>
    </Container>
  )
}

ToolCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    client: PropTypes.string,
    homepage: PropTypes.string.isRequired,
  }),
}

export default ToolCard
