import React, { createContext, useEffect, useState, useContext } from 'react';
import cookie from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { useAPI } from '../hooks'
import GlobalContext from './globalContext';

export const PermissionContext = createContext()

const permissionsMap = {
  academy_staff: [ 'academy-builder' ],
  service_staff: [ 'service-note' ],
  support_staff: [ 'log-viewer' ,'device-register' ],
  technical_staff: [ 'composer' ],
  super_user: [ 'log-viewer' ]
}

const PermissionProvider = ({ children }) => {
  const [ { isAuthenticated } ] = useContext(GlobalContext)
  const { isLoading, isError, data } = useAPI('tools', [])
  const [ permittedTools, setPermittedTools ] = useState([])
  const [ userPermissions, setUserPermissions ] = useState([])

  useEffect(() => {
    if (isAuthenticated) {
      const token = cookie.get('token')
      const { scope } = jwtDecode(token)
      const { user_permissions } = scope

      setUserPermissions(user_permissions)
    }

  },[isAuthenticated])

  useEffect(() => {
    const toolsMap = createToolsMap(data)
    let toolsList = [ toolsMap['serial-checker'] ]

    if ( userPermissions.length === 0) {
      setPermittedTools(toolsList)
      return
    }

    if ( userPermissions.indexOf('api_master') !== -1 || userPermissions.indexOf('api_admin') !== -1)  {
      setPermittedTools(data)
      return
    }

    if ( userPermissions.indexOf('service_staff') !== -1) {
      permissionsMap['service_staff'].forEach(tool => {
        if (toolsList.indexOf(toolsMap[tool]) === -1)  {
          toolsList = [ toolsMap[tool], ...toolsList ]
        }
      })
    }

    if ( userPermissions.indexOf('super_user') !== -1) {
      permissionsMap['super_user'].forEach(tool => {
        if (toolsList.indexOf(toolsMap[tool]) === -1)  {
          toolsList = [ toolsMap[tool], ...toolsList ]
        }
      })
    }

    if ( userPermissions.indexOf('technical_staff') !== -1) {
      permissionsMap['technical_staff'].forEach(tool => {
        if (toolsList.indexOf(toolsMap[tool]) === -1)  {
          toolsList = [ toolsMap[tool], ...toolsList ]
        }
      })
    }

    if ( userPermissions.indexOf('support_staff') !== -1) {
      permissionsMap['support_staff'].forEach(tool => {
        if (toolsList.indexOf(toolsMap[tool]) === -1)  {
          toolsList = [ toolsMap[tool], ...toolsList ]
        }
      })
    }

    if ( userPermissions.indexOf('academy_staff') !== -1) {
      permissionsMap['academy_staff'].forEach(tool => {
        if (toolsList.indexOf(toolsMap[tool]) === -1)  {
          toolsList = [ toolsMap[tool], ...toolsList ]
        }
      })
    }

    setPermittedTools(toolsList)
  }, [userPermissions, data])

  const permittedToolsList = {
    isLoading,
    isError,
    permittedTools
  }

  return (
    <PermissionContext.Provider value={permittedToolsList}>
      {children}
    </PermissionContext.Provider>
  )
}

export default PermissionProvider

const createToolsMap = toolsList => {
  const toolsMap = {}

  toolsList.forEach(tool => {
    toolsMap[tool.name] = tool
  })

  return toolsMap
}
